
import { computed, defineComponent, onMounted } from "vue";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";
import { useStore } from "vuex";
import { openLinkAgriBest } from "@/core/helpers/globalJaya";
import CardTitleJaya from "@/components/global/CardTitleJaya.vue";
import { Actions } from "@/store/enums/StoreEnums";

export default defineComponent({
  name: "faq",
  components: { CardTitleJaya },
  methods: { openLinkAgriBest },
  setup() {
    const store = useStore();

    const factors = computed(() => store.getters.getFactors);
    const factorGroups = computed(() => store.getters.getFactorGroups);

    onMounted(() => {
      setCurrentPageTitle("Aller plus loin");
      store.dispatch(Actions.FETCH_FACTOR_GROUPS).then(() => {
        store.dispatch(Actions.FETCH_QUIZ);
      });
    });

    return {
      factorGroups,
      factors,
    };
  },
});
